<template>
  <div>
    <BundleDetails />
  </div>
</template>

<script>
// @ is an alias to /src
import BundleDetails from '@/components/BundleDetails.vue'

export default {
  name: 'BundleView',
  components: {
    BundleDetails
  }
}
</script>
