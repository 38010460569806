<template>
  <div class="q-pa-md row items-start">
    <q-card flat bordered square class="my-card">
      <q-card-section>
        <div class="row">
          <q-input
            v-model="bundleCode"
            placeholder="Bundle ID"
            dense
            outlined
            class="q-mr-sm q-pr-none bg-white"
            @keydown.native.enter="search"
          ></q-input>
          <q-btn color="primary" icon="search" round :loading="loading" @click="search">
            <template v-slot:loading>
              <q-spinner-hourglass />
            </template>
          </q-btn>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'BundleDetails',

  data: function () {
    return {
      bundleCode: '',
      loading: false
    }
  },

  methods: {
    search() {
      if (this.bundleCode.length > 0) {
        this.loading = true
        this.$store
          .dispatch('DOWNLOAD_RESULTS', this.bundleCode)
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          });
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
